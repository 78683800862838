import * as Constantes from '../../shared/constants';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Servicios
import { PreparacionesService } from 'src/app/services/datos/preparaciones.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { UsuarioService } from 'src/app/services/datos/usuario.service';
import { RepartosService } from 'src/app/services/datos/repartos.service';

// interfaces y modelos
import { IArtPreparacion } from 'src/app/interfaces/artPreparacion';
import { ICampanya } from 'src/app/interfaces/campanya';
interface IPreparacionesAgrupadas {
  [uic: string]: IArtPreparacion[];
}

// librerias
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { IPedido } from 'src/app/interfaces/pedido';


@Component({
  selector: 'app-prepara-detall',
  templateUrl: './prepara-detall.component.html',
  styleUrls: ['./prepara-detall.component.scss']
})
export class PreparaDetallComponent implements OnInit {

  //Usar constantes en el template
  Constantes = Constantes;

  loading: boolean = false;
  recogida_cod: string = '';
  public preparacionesList: IArtPreparacion[] = [];
  public preparacionesAgrupadas: IPreparacionesAgrupadas = {};
  public campanya: ICampanya = {};
  private pedido: IPedido = {};

  public totalUbis: number = 0;

  constructor(
    private usuarioSrv: UsuarioService,
    private prepracionSrv: PreparacionesService,
    private notificacionesSrv: NotificacionesService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private RepartoSrv: RepartosService,
  ) { }

  ngOnInit(): void {

    // Suscribirse a los parámetros de la url, envían una id, cargar los datos del articulo
    this.activatedRoute.params.subscribe(({ recogida_cod }) => {
      this.recogida_cod = recogida_cod;
      this.cargaPreparacionList();
    })
  }


  cargaPreparacionList() {
    this.loading = true;


    const data = {
      recogida_cod: this.recogida_cod || null,
      todo: '1'
    };

    this.prepracionSrv.listRecogidaCod(data)
      .subscribe(resp => {
        this.loading = false;
        console.log('__cargaPreparacionList() resp', resp);
        if (resp.error) this.notificacionesSrv.aviso('error', resp.mensaje);
        this.preparacionesList = resp.data;
        this.totalUbis = resp.totales.ubis;
        this.campanya = resp.totales.campanya;
        this.pedido = resp.totales.pedido;

        this.preparacionesAgrupadas = this.agruparPorUIC();
        console.log('__cargaPreparacionList() preparacionesAgrupadas', this.preparacionesAgrupadas);

      })
  }

  private agruparPorUIC(): { [key: string]: any[] } {
    const agrupadas: { [key: string]: any[] } = {};

    for (const preparacion of this.preparacionesList) {
      let uic = preparacion.uic;

      // Si uic está vacío, asigna una clave especial
      if (!uic) {
        uic = 'Incidencias';
      }

      if (!agrupadas[uic]) agrupadas[uic] = [];
      agrupadas[uic].push(preparacion);
    }


    // Obtiene las claves (UIC) y las ordena alfabéticamente
    const uicKeys = Object.keys(agrupadas).sort();

    // Devielve el objeto ordenado por UIC 
    const agrupadasOrdenadas: { [key: string]: any[] } = {};
    for (const key of uicKeys) {
      agrupadasOrdenadas[key] = agrupadas[key];
    }

    return agrupadasOrdenadas;

  }

  enviarPedidoClick() {
    // Envía pedido, es una campaña 1 a 1 de un solo pedido

    if (this.pedido.estado === Constantes.ESTADO_PEDIDO_SERVIDO_PARCIALMENTE) {
      this.notificacionesSrv.aviso('error', `El pedido ${this.pedido.pedido_cli} ya se ha servido parcialmente`);
      return
    }

    let titulo: string = '¿Enviar pedido?';
    let icono: SweetAlertIcon = 'question';
    let html: string = 'Se generará el albarán y la orden de envío para el pedido <b>' + this.pedido.pedido_cli + '</b>.';

    if (this.pedido.cod_servicio === 'inte') html = 'Se generará el albarán, pero como el servicio del pedido es <span class="text-warning"><b>Inte</b></span> <h2>No se generara la orden de envío</h2> para el pedido <b>' + this.pedido.pedido_cli + '</b>.';

    // if (totales.cantRecogida !== totales.cantReserva) {
    //   titulo = "No está todo recogido!";
    //   icono = "warning" as SweetAlertIcon;
    //   html = `Solo se han recogido ${totales.cantRecogida} de ${totales.cantReserva} artículos.<br>¿Quieres servir el pedido con faltas?`;
    // }

    Swal.fire({
      title: titulo,
      icon: icono,
      html: html,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {

        const data = {
          pedido: { ...this.pedido, id_pedido: this.pedido.id, cajetin: '1' },
          id_usuario: this.usuarioSrv.userdata.id
        };
        console.log('__enviarPedidoClick() data', data);
        this.loading = true;
        this.RepartoSrv.pedidoSaleUnoaUno(data)
          .subscribe(resp => {
            this.loading = false;
            console.log('pedidoSaleUnoaUno()', resp);
            if (resp.error) {
              this.notificacionesSrv.aviso('error', resp.mensaje);
              return
            }
            this.notificacionesSrv.aviso('success', resp.mensaje);
            this.volverClick();
          });

      }
    })

  }

  /// AUX  ///

  public getPreparacionesAgrupadasKeys(): string[] {
    // Getter del key
    return Object.keys(this.preparacionesAgrupadas);
  }

  volverClick() {
    this.router.navigate(['./pda']);
    return
  }


} 