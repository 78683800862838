// Módulos
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Observable, catchError, throwError } from 'rxjs';

// Servicios
import { NotificacionesService } from '../notificaciones.service';

// Interfaces
import { IApiResp } from 'src/app/interfaces/api-resp';
import { IPedido } from 'src/app/interfaces/pedido';
import { IListaOpciones } from 'src/app/interfaces/lista-opciones';
import { AvisosService } from './avisos.service';
import { ICampanya } from 'src/app/interfaces/campanya';

@Injectable({
  providedIn: 'root'
})
export class PedidosService {

  apiURL: string = environment.apiURL;
  public selected: any;                   // Línea seleccionada

  constructor(
    private http: HttpClient,
    private router: Router,
    private notificacionesSrv: NotificacionesService,
  ) { }


  getPedido(id: number) {
    const path = `${this.apiURL}/pedidos/list/${id}`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }



  getLista(_opciones: IListaOpciones = {}) {
    // Devulve la lista filtrada
    const path = `${this.apiURL}/pedidos/listFiltro`;

    const data = {
      id: _opciones.id || null,
      id_almacen: _opciones.id_almacen || null,
      id_cliente: _opciones.id_cliente || null,
      id_articulo: _opciones.id_articulo || null,
      id_ubicacion: _opciones.id_ubicacion || null,
      id_campanya: _opciones.id_campanya || null,

      swCompleto: _opciones.swCompleto || null,
      estado: _opciones.estado || null,
      cod_servicio: _opciones.cod_servicio || null,
    };

    return this.http.post<IApiResp>(path, data)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          return throwError(() => new Error(error));
        })
      )
  }


  getCajetinesCampanya(camp_cod: string) {
    // Devuelve los cajetínes de una campaña
    const path = `${this.apiURL}/pedidos/getCajetinesCampanya/${camp_cod}`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }

  getCampanyasSinEmbalar() {
    // Devuelve las campañas con pedidos sin embalaje 
    const path = `${this.apiURL}/pedidos/getCampanyasSinEmbalar`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }

  getPedidosSinEmbalar(id_campanya: string) {
    // Devuelve los pedidos sin embalaje de la campaña id_campaña
    const path = `${this.apiURL}/pedidos/getPedidosSinEmbalar/${id_campanya}`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }

  setPedidosEmbalados(id_campanya: string) {
    //Marca los pedidos embalaje EDITADO para la campaña id_campaña
    const path = `${this.apiURL}/pedidos/setPedidosEmbalados/${id_campanya}`;
    const resp = this.http.get<IApiResp>(path)
    return resp;
  }




  downloadAlbaran(id_pedido: number) {
    // devuelve la ruta a un pdf
    const path = `${this.apiURL}/pedidos/downloadAlbaran/${id_pedido}`;
    const resp = this.http.get<IApiResp>(path)
    return resp;
  }



  new(formData: any) {
    const path = `${this.apiURL}/pedidos/new`;
    return this.http.post<IApiResp>(path, formData)
  }

  update(pedido: IPedido) {
    const path = `${this.apiURL}/pedidos/update/${pedido.id}`;
    return this.http.post<IApiResp>(path, pedido)
  }

  delete(id: number) {
    const path = `${this.apiURL}/pedidos/delete/${id}`;
    return this.http.get<IApiResp>(path)
  }

  marcarAviso(id: number) {
    const path = `${this.apiURL}/pedidos/marcarAviso/${id}`;
    return this.http.get<IApiResp>(path)
  }


  marcarAvisoCampanya(campanya: ICampanya) {
    const id_campanya = campanya.id;
    const path = `${this.apiURL}/pedidos/marcarAvisoCampanya/${id_campanya}`;
    return this.http.get<IApiResp>(path)
  }

  ///// AUX ////

  pedidoLabel(data: any) {
    const path = `${this.apiURL}/pedidos/pedidoLabel`;
    return this.http.post<IApiResp>(path, data)
  }

}
